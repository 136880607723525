
import { CandidatesList } from '@/dtos/CandidatesListDTO';
import SearchInput from '@/components/SearchInput.vue';
import { defineComponent, watch, reactive, onMounted, computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { format, parse } from 'date-fns';
import vm from "@/viewModels/MainViewModel";
import { VueDraggableNext } from 'vue-draggable-next'
import ExameService from '@/services/ExameService';
import Banner from '@/components/Banner.vue';
import CandidateCard from '@/components/CandidateCardNew.vue';
import Loading from "@/components/Loading.vue";
import DropdownFilterButton from '@/components/DropdownFilterButton.vue';
import Icon from '@/components/Icon.vue';
import { removeCpfMask } from '@/utils/stringFunctions';
import { verifyCpf } from '@/utils/validationFunctions';
import CalendarInclude from '../admin/exame/CalendarInclude.vue';
import CandidateFilter from '../admin/exame/CandidateFilter.vue';
import UsuarioService from '@/services/UsuarioService';
import ExaminadorDTO from '@/dtos/ExaminadorDTO';
import { useAlert } from '@/hooks/alert';
import { trackers } from '@/hooks/insights';
import ExamsQuery from '@/queryObjects/ExamsQuery';

enum SchedulingStatusEnum {
    NONE = 'Em aberto',
    STARTED = 'Em andamento',
    DONE = 'Executado',
}

enum SchedulingStatusResultEnum {
    WAITING_AUDITING = 'Aguardando auditoria',
    IN_ANALYSIS = 'Em análise',
    WAITING_CONFIRMATION = 'Aguardando Examinador',
    DISQUALIFIED = 'Desclassificado',
    RELEASED = 'Liberado'
}

interface CandidatesState {
    candidates: CandidatesList[];
    examiners: ExaminadorDTO[] | null;
    selectedCandidates: CandidatesList[];
    selectedCandidates1: CandidatesList[];
    candidatesFiltered: CandidatesList[];
    endedPages: boolean;
    examDate: string;
    resultStatus?: number;
    loading: boolean;
    id: string;
    page: number;
    selectedDate: Date | string;
    status?: number;
    order?: number;
    search?: string;
    title: string;
    pageSize: number;
    disableNext: boolean;
    qtd: number;
    calendarOpened: boolean;
    confirmAudit: boolean;
    errorAudit: boolean;
    auditID: string;
    gridApi: string | null;
    collumApi: string | null;
    examiner: string;
    examiner1: string;
    cpf: string;
}

type PropsRequerid = 'pageSize' | 'page' | 'checkResultStatusPerRole';
type QueryType = Partial<Omit<ExamsQuery, PropsRequerid>> & Pick<ExamsQuery, PropsRequerid>;

const TransferExaminerView = defineComponent({
    components: { Banner, CandidateFilter, CandidateCard, Loading, CalendarInclude, DropdownFilterButton, Icon, SearchInput, draggable: VueDraggableNext, },

    props: {
        totalCandidates: {
            type: Number,
            default: 0
        }
    },
    methods: {
        onDragOver(event: DragEvent) {
            var dragSupported = event.dataTransfer?.items.length;
            if (dragSupported) {
                event.dataTransfer ? event.dataTransfer.dropEffect = 'move' : "";
            }
            event.preventDefault();
        },
        onDrop(event: DragEvent) {
            var jsonData = event.dataTransfer?.getData('application/json');
            var eJsonRow = document.createElement('div');
            eJsonRow.classList.add('json-row');
            eJsonRow.innerText = jsonData + "";
            var eJsonDisplay = document.querySelector('#eJsonDisplay');
            eJsonDisplay?.appendChild(eJsonRow);
            event.preventDefault();
        },
        onGridReady(state: CandidatesState) {
            state.gridApi = "1";
            state.collumApi = "1";
        },
    },
    setup() {
        const today = new Date();
        const route = useRoute();
        const { replace, push } = useRouter();
        const alert = useAlert();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        const state = reactive<CandidatesState>({
            candidates: [],
            examiners: null,
            selectedCandidates: [],
            selectedCandidates1: [],
            candidatesFiltered: [],
            endedPages: false,
            examDate: "",
            resultStatus: undefined,
            loading: false,
            id: "",
            page: 1,
            selectedDate: "",
            status: undefined,
            search: undefined,
            order: undefined,
            title: " ",
            pageSize: 5,
            disableNext: false,
            qtd: 0,

            calendarOpened: false,
            confirmAudit: false,
            errorAudit: false,
            auditID: "",
            gridApi: null,
            collumApi: null,
            examiner: "",
            examiner1: "",
            cpf: ""
        });

        // PAGINAÇÃO
        const query = (page: number) => {
            replace({ query: { ...route.query, page: page } });
        };

        const nextPage = (page: number) => {
            query(page);
            window.scrollTo(0, 0);
        };

        const formattedDate = computed(() => {
            if (state.selectedDate) {
                return format(new Date(state.selectedDate), "dd/MM/yyyy");
            } else {
                return "--/--/----";
            }
        });

        const loadCandidates = () => {
            state.loading = true;

            state.status = route.query["status"] ? parseInt(route.query["status"].toString()) : undefined;
            //fixed exams WAITING_AUDIT
            state.resultStatus = 3;
            state.id = route.params["id"] ? route.params["id"].toString() : "";
            state.search = route.query["search"] ? route.query["search"].toString() : "";
            state.order = route.query["order"] ? parseInt(route.query["order"].toString()) : undefined;
            state.page = route.query["page"] ? parseInt(route.query["page"].toString()) : 1;
            state.pageSize = route.query["pageSize"] ? parseInt(route.query["pageSize"].toString()) : 50;

            // Calendário
            state.selectedDate = route.query["date"] ? new Date(route.query["date"].toString()) : "";
            const date = route.query["date"] ? new Date(route.query["date"].toString()) : undefined;
            const startHour = route.query["startHour"] ? parse((route.query["startHour"] as string) || "00h", "HH'h'", new Date()).getHours() : undefined;
            const finishHour = route.query["finishHour"] ? parse((route.query["finishHour"] as string) || "23h", "HH'h'", new Date()).getHours() : undefined;

            let data = {
                page: state.page,
                pageSize: state.pageSize,
                date: date,
                startHour: startHour,
                finishHour: finishHour,
                status: state.status,
                resultStatus: state.resultStatus,
                federativeUnit: vm.user?.federativeUnit,
                noDetails: true,
                ResultStatus: 3
            };

            if (verifyCpf(state.search)) {
                data = Object.assign({ viewerCpf: removeCpfMask(state.search) }, data);
            } else {
                data = Object.assign({ viewerName: state.search }, data);
            }

            // Filtros
            const [request] = ExameService.GetExam(data);
            request
                .then((resp) => {
                    state.candidates = resp.content;
                    state.candidates.map(x => {
                        if (x.viewerCpf !== vm.user?.cpf && x.viewerCpf != undefined && x.viewerCpf != "" && vm.user?.roleDescription != "ADMIN") {
                            x.statusResult = "EXAMINADOR ESPECIFICO";
                        }
                    });
                    state.qtd = resp.itensCount;
                    state.candidatesFiltered = resp;
                    if (resp.length < state.pageSize) {
                        state.disableNext = true;
                    } else {
                        state.disableNext = false;
                    }
                })
                .catch((error) => {

                    state.candidates = [];
                    alert({
                        message: `${error}`
                    })
                    trackers.registerError(state.auditID, error.toString());
                }
                )
                .finally(() => {
                    return state.loading = false;
                });
        };

        const getStatus = (status: string): string => {
            return SchedulingStatusEnum[status as keyof typeof SchedulingStatusEnum];
        };
        const getStatusResult = (statusResult: string): string => {
            return SchedulingStatusResultEnum[statusResult as keyof typeof SchedulingStatusResultEnum];
        };


        watch(() => route.query, loadCandidates, { deep: true });


        onMounted(() => {
            getAllExaminers();
            loadCandidates();
        });

        const getAllExaminers = () => {

            var [request, canceler] = UsuarioService.getAllExaminers()
            request.then(x => {
                state.examiners = x;
            })
        };

        const transferExaminer = () => {
            var ids: string[] = [];
            state.selectedCandidates.map(x => {
                ids.push(x.id)
            })
            var [request, canceler] = ExameService.UpdateExaminerExams(state.examiner, ids)
            request.then(x => {
                trackers.registerExaminerTransfered(state.examiner, "Auditorias transferidas")
                alert({
                    title: "Sucesso",
                    message: "Auditorias transferidas"
                })
                state.selectedCandidates = [];
            }).catch(error => {
                trackers.registerError(state.examiner, `Erro ao transferir auditorias: ${error.toString()}`)
                alert({
                    title: "Erro",
                    message: error
                })
            })
        }
        const transferExaminer1 = () => {
            var ids: string[] = [];
            state.selectedCandidates1.map(x => {
                ids.push(x.id)
            })
            var [request, canceler] = ExameService.UpdateExaminerExams(state.examiner, ids)
            request.then(() => {
                trackers.registerExaminerTransfered(state.examiner, "Auditorias transferidas")
                alert({
                    title: "Sucesso",
                    message: "Auditorias transferidas"
                })
                state.selectedCandidates1 = [];
            }).catch(error => {
                trackers.registerError(state.examiner, `Erro ao transferir auditorias: ${error.toString()}`)
                alert({
                    title: "Erro",
                    message: error
                })
            })
        }

        const searchExam = async (value: string | any | null) => {
            if (state.loading) {
                return;
            }
            state.loading = true;
            state.candidates = []


            try {
                state.candidates = await getExams({ viewerCpf: isValidString(value).replace(new RegExp(/\D/g), '') } as QueryType);
            } catch (error) {
                trackers.registerError('Erro ao buscar candidatos', error!.toString());
                state.candidates = [];
            } finally {
                state.loading = false;
            }
        };

        const getExams = (arg: QueryType): Promise<CandidatesList[]> => {
            return new Promise((resolve, reject) => {
                arg.checkResultStatusPerRole = true;
                arg.noDetails = true;
                arg.resultStatus = 3
                const [request] = ExameService.GetExam({ ...arg });
                request
                    .then((resp) => {
                        resolve(resp.content as CandidatesList[]);

                        state.qtd = resp.itensCount;
                    })
                    .catch(reject)
                    .finally(() => {
                        state.loading = false;
                    });
            });
        };

        function isValidString(value: string | undefined | null): string {
            if (value !== null && value !== undefined) {
                return value.trim();
            }
            return '';
        }

        return {
            SchedulingStatusEnum,
            SchedulingResultStatusEnum: SchedulingStatusResultEnum,
            formattedDate,
            getStatus,
            getStatusResult,
            state,
            nextPage,
            transferExaminer,
            transferExaminer1,
            searchExam
        };
    },
});

export default TransferExaminerView;
