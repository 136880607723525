
import { computed, defineComponent } from "vue";
import Icon from "@/components/Icon.vue";
import { maska } from "maska";

const SearchInput = defineComponent({
    directives: { maska },
    components: { Icon },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }, 
        mask: {
            type: String,
            default: ''
        }
    },
    emits: ['search', 'update:modelValue'],
    setup(props, context) {

        const inputValue = computed({
            get() {
                return props.modelValue;
            },
            set(value: string) {
                context.emit("update:modelValue", value);
            }
        });

        const onChangeValue = (valueChange: Event) => {            
            const valueCPF = (valueChange.target as HTMLInputElement).value;
            const isValid = (/^[0-9]{3}.[0-9]{3}.?[0-9]{3}-[0-9]{2}$/i).test(valueCPF);
            if (!isValid) return;

            context.emit('search', valueCPF);
        };

        const clearInput = () => {
            inputValue.value = '';
            context.emit('search', null);
        }

        return { inputValue, onChangeValue, clearInput };
    }
});

export default SearchInput;
