import { renderSlot as _renderSlot, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
  role: "menu",
  "aria-orientation": "vertical",
  "aria-labelledby": "menu-button",
  tabindex: "-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, [
      _createVNode("button", {
        class: "border border-primary rounded-full font-medium bg-white px-4 py-2 flex align-start justify-between dropdown",
        onClick: _cache[1] || (_cache[1] = () => _ctx.state.opened = !_ctx.state.opened)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    (_ctx.state.opened)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", {
            class: "py-1",
            onClick: _cache[2] || (_cache[2] = () => _ctx.state.opened = !_ctx.state.opened)
          }, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
              return (_openBlock(), _createBlock("a", {
                key: index,
                class: ["text-gray-700 block px-4 py-2 text-sm cursor-pointer", {
                        'font-bold': index === _ctx.state.selected,
                    }],
                onClick: ($event: any) => (_ctx.handleSetOption(index, option.value))
              }, _toDisplayString(option.label), 11, ["onClick"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}