import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    class: ["border border-primary rounded-full font-medium", {
            'bg-primary text-white px-4 py-2': _ctx.active, 
            'bg-white text-primary px-4 py-2': !_ctx.active
        }]
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}