
import { defineComponent, reactive, watchEffect } from 'vue';
import Calendar from 'primevue/calendar';
import Range from '@/components/Range.vue';
import { formatISO } from 'date-fns';

const CalendarInclude = defineComponent({
    components: { Calendar, Range },
    props: {
        dateTime: {
            type: Date,
            default: new Date()
        },
        startHour: {
            type: Number,
            required: true
        },
        finishHour: {
            type: Number,
            required: true 
        },
    },
    emits: ['openClose','selectedDate', 'filter', 'cancelCalendar', 'update:dateTime', 'update:startHour', 'update:finishHour'],
    setup(props, context) {
        const hours = ['00h', '08h', '12h', '18h', '23h'];

        const state = reactive({
            selectedDateTemp: props.dateTime,
            startHour: props.startHour,
            finishHour: props.finishHour,
            openSelectDate: false,
        });

        watchEffect(() => {
            context.emit('update:dateTime', state.selectedDateTemp);
            context.emit('update:startHour', state.startHour);
            context.emit('update:finishHour', state.finishHour);
        });

        const filter = () => {
            const newQuery: { [key: string]: any } = {};
            newQuery['startHour'] = hours[state.startHour];
            newQuery['finishHour'] = hours[state.finishHour];
            newQuery['date'] = formatISO(state.selectedDateTemp);

            context.emit('filter', newQuery);
            context.emit('update:dateTime', state.selectedDateTemp);
            context.emit('openClose');
        };

        return { state, filter, hours, open };
    }
});

export default CalendarInclude;
