
import { defineComponent } from 'vue';

const StatusFilterButton = defineComponent({
    props: {
        active: {
            type: Boolean
        }
    }
});

export default StatusFilterButton;
