
import { defineComponent, PropType, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

type OptionsType = {
    label: string
    value: string
};

const DropdownFilterButton = defineComponent({
    props: {
        options: {
            type: Object as PropType<OptionsType>,
            required: true,
        }
    },
    setup() {
        const { replace } = useRouter();
        const route = useRoute();

        const formatOptions = (status: number) => {
            switch (status) {
                case 5:
                    return 0;
                case 10:
                    return 1;
                case 20:
                    return 2;
                case 50:
                    return 3;
            }
        };

        const state = reactive({
            opened: false,
            selected: formatOptions(route.query["pageSize"] ? parseInt(route.query["pageSize"].toString()) : 5),
            value: route.query.examStatus || '',
        });

        const closeModal = () => {
            state.opened = false;
        };

        const handleSetOption = (option: number, value: string) => {
            state.value = value;
            state.selected = option;
        };

        const pageSize = () => {
            const pageSize = state.value.length > 0 ? state.value : null;
            replace({ query: { ...route.query, pageSize } });
        };

        watch(() => state.value, () => {
            pageSize();
        });

        return {
            state,
            handleSetOption,
            closeModal
        };
    }
});

export default DropdownFilterButton;
