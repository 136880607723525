
import { computed, defineComponent, ref } from 'vue';

const Range = defineComponent({
    props: {
        options: {
            type: Array,
            required: true
        },
        minIndex: {
            type: Number,
            required: true
        },
        maxIndex: {
            type: Number,
            required: true
        }
    },
    setup(props, context){


        const firstInputValue = ref(props.minIndex);
        const secondInputValue = ref(props.maxIndex);

        const minIndexValue = computed(() => {
            const value = Math.min(firstInputValue.value, secondInputValue.value);
            context.emit('update:minIndex', value);
            return value;
        });
        const maxIndexValue = computed(() => {
            const value = Math.max(firstInputValue.value, secondInputValue.value);
            context.emit('update:maxIndex', value);
            return value;
        });

        const stepSize = computed(() => (100 / (props.options.length - 1)));

        return { firstInputValue, secondInputValue, minIndexValue, maxIndexValue, stepSize };
    }
});

export default Range;
