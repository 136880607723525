
import { defineComponent, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DropdownFilterButton from '@/components/DropdownFilterButton.vue';
import Icon from '@/components/Icon.vue';
import StatusFilterButton from '@/components/StatusFilterButton.vue';
import vm from "@/viewModels/MainViewModel";

const CandidateFilter = defineComponent({
    emits: ['selected'],
    components: { DropdownFilterButton, Icon, StatusFilterButton },
    setup(){
        const { replace } = useRouter();
        const route = useRoute();
        
        const state = reactive({
            filterBy: route.query.status || "", 
            filterByScheduling: route.query.status || "", 
            selected: -1,
            schedulingSelected: -2,
        });

        const setFilter = (value: number) => {
            state.filterBy = value.toString();
            if (value === -1) {
                state.filterBy = '';
                filter();
            }
            state.filterByScheduling = '';

            state.selected = value;
            state.schedulingSelected = -2;
        };

        const setFilterScheduling = (value: number) => {
            state.filterBy = '';
            state.filterByScheduling = value.toString();

            state.selected = -2;
            state.schedulingSelected = value;
        };

        const filter = () => {
            const status = state.filterBy.length > 0 ? state.filterBy : null;

            // Take off resultStatus of query
            let query = Object.assign({}, route.query);
            delete query.resultStatus;

            replace({ query: { ...query, status, search: '', page: 1 } });
        };

        const filterScheduling = () => {
            const resultStatus = state.filterByScheduling.length > 0 ? state.filterByScheduling : null;

            // Take off status of query
            let query = Object.assign({}, route.query);
            delete query.status;

            replace({ query: { ...query, resultStatus, search: '', page: 1 } });
        };

        watch(() => state.filterBy, () => {
            if (state.filterBy) {
                filter();
            }
        });

        watch(() => state.filterByScheduling, () => {
            if (state.filterByScheduling) {
                filterScheduling();
            }
        });
        
        const dropdown = ref();
        const closeModal = () => {
            if (dropdown.value) dropdown.value.closeModal();
        };

        return { state, setFilter, setFilterScheduling, filter, closeModal, dropdown, vm };
    }
});

export default CandidateFilter;
