import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between h-14 w-full bg-white " }
const _hoisted_2 = { class: "ml-5 mr-5 flex flex-1 " }
const _hoisted_3 = { class: "flex items-center justify-start" }
const _hoisted_4 = { class: "relative w-full flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")
  const _directive_maska = _resolveDirective("maska")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("button", {
          class: "rounded absolute opacity-30 z-30 bg-gray-50",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('search', _ctx.inputValue))),
          tabindex: "0"
        }, [
          _createVNode(_component_Icon, {
            icon: "search",
            color: "#363738",
            class: "m-2"
          })
        ])
      ]),
      _createVNode("div", _hoisted_4, [
        _withDirectives(_createVNode("input", {
          type: "text",
          maxlength: _ctx.mask.length,
          placeholder: _ctx.placeholder,
          class: "h-10 relative w-80 text-sm w-full rounded text-left pl-10 text-neutral-4 bg-gray-50 border border-2",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputValue = $event)),
          tabindex: "0",
          onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onChangeValue && _ctx.onChangeValue(...args)))
        }, null, 40, ["maxlength", "placeholder"]), [
          [_vModelText, _ctx.inputValue],
          [_directive_maska, _ctx.mask]
        ]),
        (_ctx.inputValue.length > 0)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              onClick: _ctx.clearInput,
              icon: "close",
              color: "#363738",
              class: "absolute bottom-1 m-2 cursor-pointer"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}