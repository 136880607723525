
import { defineComponent, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Icon from '@/components/Icon.vue';
import SearchInput from '@/components/SearchInput.vue';
import HomeIllustration from "@/components/illustrations/HomeIllustration.vue";

const Banner = defineComponent({
    components: { Icon, SearchInput, HomeIllustration },
    props: {
        searchPlaceholder: {
            type: String,
            default: "Pesquise"
        },
        handleBackPath: {
            type: String,
            default: "/"
        }
    },
    setup(){
        const { replace } = useRouter();
        const route = useRoute();
        
        const state = reactive({
            serch: route.query.serch || "",
        });

        const setFilter = (value: number) => {
            state.serch = value.toString();
        };

        const filter = () => {
            const search = state.serch.length > 0 ? state.serch : null;
            replace({ query: { ...route.query, search } });
        };

        watch(() => state.serch, () => {
            filter();
        });
        

        return { state, setFilter, filter };
    }
});

export default Banner;
