
import { computed, defineComponent, onMounted, reactive } from "vue";
import Icon from "@/components/Icon.vue";
import { format } from "date-fns";
import { maskCpf } from '@/utils/stringFunctions';
import vm from "@/viewModels/MainViewModel";
import UsuarioService from "@/services/UsuarioService";

const CandidateCard = defineComponent({
    components: {
        Icon
    },
    emits: ['startAudit'],
    props: {
        cpf: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        notValidated: {
            type: Number,
            default: 0,
        },
        refused: {
            type: Number,
            default: 0,
        },
        status: {
            type: String,
            required: true,
        },
        statusResult: {
            type: String,
            required: true,
        },
        validated: {
            type: Number,
            default: 0,
        },
        id: {
            type: Number,
            required: true
        },
        examId: {
            type: String,
            required: true
        },
        viewerCpf: {
            type: String,
            required: false
        },
        schedulingId: {
            type: String,
            required: false
        },
        viewerName: {
            type: String,
            required: false
        },
        newViewerCpf: {
            type: String,
            required: false
        },
    },
    setup(props) {
        const state = reactive({
            title: '',
            color: '#2CC89B',
            formattedDate: '',
            viewerName: '',
            newViewerName: ''
        });

        if (props.newViewerCpf !== undefined && props.newViewerCpf != "") {
            const [request, canceler] = UsuarioService.getUserCpf(`${props.newViewerCpf}`);
            request.then(x => {
                state.newViewerName = x.name.toLocaleUpperCase();
            });
        }
        if (props.viewerCpf !== undefined && props.viewerCpf !== "") {
            const [request1, canceler1] = UsuarioService.getUserCpf(`${props.viewerCpf}`);
            request1.then(x => {
                state.viewerName = x.name.toLocaleUpperCase();
            });
        }

        const disabledBtn = computed(() => {
            if (vm.user?.roleDescription === 'ADMIN') {
                return props.statusResult != 'Aguardando auditoria' && props.statusResult != 'Em análise' && props.statusResult != 'Aguardando confirmação do examinador';

            } else if (vm.user?.roleDescription === 'VIEWER') {
                return props.statusResult != 'Aguardando confirmação do examinador' && props.statusResult != 'Desclassificado' && props.statusResult != 'Liberado';
            }
            return null;
        });

        const handleGenerateData = () => {
            state.formattedDate = format(new Date(props.date), "dd/MM/yyyy");
            if (props.notValidated) {
                const title = `${props.notValidated} Alertas não validados`;
                state.title = title;
                if (props.refused) {
                    state.title = title + ` / ${props.refused} Alertas recusados`;
                }
                state.color = "#D95454";
            } else if (props.refused) {
                state.title = `${props.refused} Alertas recusados`;
                state.color = "#D95454";
            } else {
                if (props.validated) {
                    state.title = `${props.validated} Alertas validados`;
                } else {
                    state.title = "Nenhum alerta";
                }
                state.color = "#2CC89B";
            }
        };

        const alertar = (mensagem: string) => {
            alert(mensagem);
        };

        onMounted(() => handleGenerateData());

        return { state, disabledBtn, alertar, maskCpf, vm };
    },
});

export default CandidateCard;
